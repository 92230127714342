import { Renderer2 } from '@angular/core';
import { IBizReport, IBizReportSettings, IBizReportLayout } from './IBizReport';
import { IReportRow, ReportData, ReportDataSets, ReportRowType } from './ReportData';

export class ReportRender {
    private _report: IBizReport;
    private _dataSets: ReportDataSets;
    private _lang: string;
    private _settings: IBizReportSettings;
    public numberFormat: Intl.NumberFormat;

    constructor(report: IBizReport, dataSets: ReportDataSets, settings: IBizReportSettings) {
        this._settings = settings;
        this._report = report;
        this._dataSets = dataSets;
        this._lang = this._settings.lang || 'NO';

        const locale = this._lang === 'EN' ? 'en' : 'nb';

        this.numberFormat = new Intl.NumberFormat(locale, {
            minimumFractionDigits: settings.decimalPlaces ?? 2,
            maximumFractionDigits: settings.decimalPlaces ?? 2,
        });
    }

    public createElements(rx: Renderer2, layoutIndex = 0, hideGroupDetails = false, sortable?: boolean) {
        const container = rx.createElement('article');

        // Create title (and interpolate input-values)
        let parameters = {};
        this._report.Input?.forEach((x) => (parameters[x.Name] = x.Default));
        const title = ReportData.interpolate(this.mapLanguage(this._report.Title || this._report.Name), parameters);

        const layout = this._report.Layouts[layoutIndex];

        const addSummaryRow = !layout.Summary || layout.Summary === 'auto';

        // Produce rows (from ReportData)
        const dataResponse: { data: Array<IReportRow>; hasMoreData: boolean } = ReportData.ProcessData(
            this._report,
            this._settings,
            this._dataSets,
        );

        const tbl = this.createTableHeader(rx, layout, sortable);
        const rows = dataResponse.data || [];

        let tbody = rx.createElement('tbody');
        let dataIndex = -1;
        for (let index = 0; index < rows.length; index++) {
            const row = rows[index];
            const tr = rx.createElement('tr');

            if (!addSummaryRow && row.rowType == ReportRowType.Summary) continue;

            if (hideGroupDetails && (row.rowType === ReportRowType.Row || row.rowType === ReportRowType.GroupHeader)) {
                // Don't render rows and group headers if hideGroupDetails is true
                dataIndex++;
            } else if (row.rowType === ReportRowType.Row) {
                dataIndex++;
                let isEmpty = true;
                for (let colIndex = 0; colIndex < layout.Columns.length; colIndex++) {
                    const col = layout.Columns[colIndex];
                    const td = rx.createElement('td');
                    const cellContent = row[col.Name];
                    if (cellContent) {
                        rx.appendChild(td, rx.createText(this.formatCell(rx, td, col, cellContent)));
                        isEmpty = false;
                    }
                    rx.appendChild(tr, td);
                }
                row.dataIndex ??= dataIndex;
                rx.setAttribute(tr, 'data-index', row.dataIndex.toString());
                rx.addClass(tr, 'detail');
                if (isEmpty) {
                    rx.addClass(tr, 'empty');
                }
            } else {
                let prefix = '';
                let cls = '';
                let mappedDataIndex = dataIndex;
                switch (row.rowType) {
                    case ReportRowType.GroupFooter:
                        prefix = hideGroupDetails ? '' : 'SUM ';
                        cls = 'groupfooter';
                        break;
                    case ReportRowType.GroupHeader:
                        prefix = '';
                        cls = 'groupheader';
                        mappedDataIndex++;
                        break;
                    case ReportRowType.Summary:
                        prefix = 'SUM ';
                        cls = 'reportfooter';
                        break;
                    case ReportRowType.SubTotalFooter:
                        prefix = '';
                        cls = 'subtotalfooter';
                        dataIndex++;
                        mappedDataIndex = dataIndex;
                        break;
                }

                rx.setAttribute(tr, 'data-index', mappedDataIndex.toString());
                rx.setAttribute(tr, 'data-group', row['_groupIndex'].toString());

                rx.addClass(tr, cls);

                for (let colIndex = 0; colIndex < layout.Columns.length; colIndex++) {
                    const col = layout.Columns[colIndex];
                    if (colIndex === 0 || colIndex >= row.colSpan) {
                        const td = rx.createElement('td');
                        const span = rx.createElement('span');
                        rx.appendChild(td, span);

                        if (colIndex === 0 && row.colSpan) {
                            rx.setAttribute(td, 'colspan', '' + row.colSpan);
                            rx.appendChild(span, rx.createText(this.trimLength(prefix + (row.rowTitle || title), 50)));
                        } else {
                            const cellContent = row[col.Name];
                            if (cellContent) {
                                rx.appendChild(span, rx.createText(this.formatCell(rx, span, col, cellContent)));
                            }
                        }
                        rx.appendChild(tr, td);
                    }
                }
            }

            rx.appendChild(tbody, tr);
        }

        rx.appendChild(tbl, tbody);
        rx.appendChild(container, tbl);

        return { container: container, hasMoreData: dataResponse.hasMoreData };
    }

    createTableHeader(rx: Renderer2, layout: IBizReportLayout, sortable: boolean) {
        const table = rx.createElement('table');
        const thead = rx.createElement('thead');
        const headerRow = rx.createElement('tr');

        layout.Columns.forEach((x) => {
            const th = rx.createElement('th');

            let parameters = {};
            this._report.Input?.forEach((x) => (parameters[x.Name] = x.Default));
            let text = ReportData.interpolate(this.mapLanguage(x.Label || x.Name), parameters);

            if (x.Name && x.Name === this._settings.sortField) {
                text += this._settings.sortDirection === 'asc' ? ' ▲' : ' ▼';
            }

            rx.appendChild(th, rx.createText(text));
            rx.setAttribute(th, 'data-column-name', x.Name);
            this.formatCell(rx, th, x, undefined, true);

            if (sortable) {
                rx.addClass(th, 'sortable');
            }

            rx.appendChild(headerRow, th);
        });

        rx.appendChild(thead, headerRow);
        rx.appendChild(table, thead);
        return table;
    }

    mapLanguage(value: string): string {
        return ReportRender.translate(this._report, value, this._lang);
    }

    public static translate(rep: IBizReport, value: string, lng = 'NO') {
        if (!value) return value;
        if (value[0] === '§') {
            let name = value.substring(1);
            const wordPos = name.indexOf(' ');
            if (wordPos > 0) {
                name = name.substring(0, wordPos);
            }
            const lang = rep.I18n[lng];
            return lang ? lang[name] + (wordPos > 0 ? value.substring(wordPos + 1) : '') || name : name;
        }
        return value;
    }

    formatCell(rx: Renderer2, element: any, column: any, value?: any, isHeader = false) {
        if (column.Chars && isHeader) {
            rx.setStyle(element, 'width', column.Chars + 'rem');
        }

        if (column.Max) {
            rx.setStyle(element, 'max-width', column.Max + 'rem');
        }

        if (!column?.Format) return value;

        switch (column.Format) {
            case 'date':
                if (isHeader) rx.setStyle(element, 'width', '6em');
                if (value) {
                    return this.formatIsoDate(value);
                }
                return value;

            case 'account':
                if (isHeader) rx.setStyle(element, 'width', '5em');
                return value;

            case 'money':
                rx.addClass(element, 'money');
                if (isHeader) return value;
                if (value && value < 0) {
                    rx.addClass(element, 'minus');
                } else {
                    rx.addClass(element, 'plus');
                }
                return this.formatMoney(value);

            case 'percent':
                rx.addClass(element, 'percent');
                if (isHeader) return value;
                const result = this.formatPercent(value, column?.Decimals);
                return result !== '' ? result + '%' : '';
        }

        return value;
    }

    formatMoney(value?: number) {
        if (!value) return '';
        return this.numberFormat.format(value);
    }

    formatPercent(value?: number, decimals?: number) {
        if (isFinite(value)) {
            return value.toFixed(decimals ?? 0);
        }
        return '';
    }

    formatIsoDate(iso: string) {
        if (!iso) return '';
        if (iso.length < 8) return '';
        return iso.substring(8, 10) + '.' + iso.substring(5, 7) + '.' + iso.substring(0, 4);
    }

    trimLength(value: string, maxLength: number, addEllipsis: boolean = true): string {
        if (value && value.length > maxLength) {
            return value.substring(0, maxLength).trim() + (addEllipsis ? '..' : '');
        }
        return value;
    }
}
