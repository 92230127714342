import {
    Component,
    Input,
    SimpleChanges,
    ChangeDetectionStrategy,
    Output,
    EventEmitter,
    OnChanges,
} from '@angular/core';
import {
    IUniEditorButton,
    IUniEditorLayout,
} from '@app/components/common/uni-entity-editor/uni-editor-layout.interface';
import { IUniEditorChangeEvent } from '@app/components/common/uni-entity-editor/uni-editor-change-event.interface';
import { BehaviorSubject } from 'rxjs';
import { UniTranslationService } from '@app/services/common/translationService';

@Component({
    selector: 'uni-entity-editor-section',
    templateUrl: './uni-entity-editor-section.component.html',
    styleUrls: ['./uni-entity-editor-section.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniEntityEditorSectionComponent implements OnChanges {
    @Input() layout: IUniEditorLayout;
    @Input() model: any;
    @Input() buttons: IUniEditorButton[];
    @Output() changeEvent: EventEmitter<IUniEditorChangeEvent> = new EventEmitter<IUniEditorChangeEvent>();
    translatedLayout: IUniEditorLayout;
    formModel$ = new BehaviorSubject({});
    constructor(private translationService: UniTranslationService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['layout']) {
            this.translatedLayout = this.getTranslatedLayout(this.layout);
        }

        if (changes['model']) {
            this.formModel$.next(this.model);
        }
    }

    ngOnDestroy() {
        this.formModel$.complete();
    }

    onChange(event: SimpleChanges) {
        this.changeEvent.emit({
            model: { ...this.model },
            changes: { ...event },
        });
    }

    onButtonClick(index: number) {
        this.buttons[index].click();
    }

    private getTranslatedLayout(layout: IUniEditorLayout): IUniEditorLayout {
        return {
            ...layout,
            fieldGroups: layout.fieldGroups.map((group) => ({
                ...group,
                fields: group.fields.map((field) => {
                    this.translationService.translate(field.Label);
                    return field;
                }),
            })),
            title: this.translationService.translate(layout.title),
            descriptions:
                layout.descriptions.map((d) => ({
                    ...d,
                    title: this.translationService.translate(d.title),
                    text: this.translationService.translate(d.text),
                })) || [],
        };
    }
}
