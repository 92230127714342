import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IModalOptions, IUniModal, UniModalService } from '@uni-framework/uni-modal';
import { AuthService } from '@app/authService';
import {
    ImportOption,
    TemplateType,
    IMPORT_DATE_FORMATS,
    OrderOptions,
    InvoiceOptions,
    ImportStatement,
} from '@app/models/import-central/ImportDialogModel';
import { ISelectConfig } from '@uni-framework/ui/uniform';
import { DisclaimerModal } from '../disclaimer/disclaimer-modal';
import { ImportJobService } from '../../import-job-service';
import { PayrollRunService } from '@app/services/salary/payrollRun/payroll-run.service';
import { AccountService } from '@app/services/accounting/accountService';
import { ErrorService } from '@app/services/common/errorService';
import { ImportDefinition } from '../../customImportService';
import { CustomImportComponent } from '../custom/custom-import-component';

interface DropdownItem {
    name: string;
    value: string | number;
}

@Component({
    selector: 'import-modal',
    templateUrl: './import-modal.html',
    styleUrls: ['./import-modal.sass'],
})
export class ImportModal implements OnInit, IUniModal {
    @ViewChild('importComponent') customImportComponent: CustomImportComponent;
    options: IModalOptions = {};
    onClose = new EventEmitter();

    loading$ = new BehaviorSubject(false);
    header = 'Import';
    importOptionOverride = ImportOption.Override;

    file: File;
    isValidFileFormat: boolean = true;
    submitButtonDisabled: boolean;

    selectConfig: ISelectConfig = {
        searchable: false,
        displayProperty: 'name',
        hideDeleteButton: true,
    };

    // Product Format
    overwriteStatement: string;

    // Date format
    showDateFormatSelect: boolean;
    dateFormats = IMPORT_DATE_FORMATS;
    selectedDateFormat = this.dateFormats[0];

    // Payroll
    showPayrollRunSelect: boolean;
    payrollRunsLoading: boolean;
    payrollRuns: DropdownItem[];
    selectedPayrollRun: DropdownItem;

    //Order
    showOrderOptions: boolean;
    orderOptions = [
        { name: 'Kladd', value: OrderOptions.Draft },
        { name: 'Registrert', value: OrderOptions.Post },
    ];

    //Invoice
    showInvoiceOptions: boolean;
    invoiceOptions = [
        { name: 'Unimicro', value: InvoiceOptions.Ue },
        { name: 'V3', value: InvoiceOptions.V3 },
    ];

    selectedOrderOption = this.orderOptions[0];
    selectedInvoiceOption = this.invoiceOptions[0];
    // Duplication handling
    duplicationHandlingOptions: { label: string; value: number }[];
    duplicationHandling = ImportOption.Skip;

    // Optional importdefinition for custom import templates
    importDefinition: ImportDefinition;
    isImporting = false;
    cancelButtonLabel: 'Avbryt' | 'Tilbake' = 'Avbryt';
    submitLabel: 'Start import' | 'Lukk' = 'Start import';

    constructor(
        public authService: AuthService,
        private payrollService: PayrollRunService,
        private accountService: AccountService,
        private errorService: ErrorService,
        private modalService: UniModalService,
        private importJobService: ImportJobService,
    ) {}

    ngOnInit(): void {
        this.importDefinition = this.options.data?.definition;
        const templateType = this.options.data.templateType;
        const entityLabelPlural = this.getEntityLabelPlural(templateType);

        this.header = entityLabelPlural ? `Importer ${entityLabelPlural}` : 'Import';

        if (templateType === TemplateType.Payroll) {
            this.showDateFormatSelect = true;
            this.showPayrollRunSelect = true;
            this.loadPayrollRuns();
        }
        if (templateType === TemplateType.RecurringPayroll) {
            this.showDateFormatSelect = true;
        }

        if (templateType === TemplateType.Order) {
            this.showDateFormatSelect = true;
            this.showOrderOptions = true;
            this.duplicationHandling = ImportOption.Duplicate;
        }

        if (templateType === TemplateType.Invoice) {
            this.showDateFormatSelect = true;
            this.showInvoiceOptions = true;
            this.duplicationHandling = ImportOption.Duplicate;
        }

        if (
            templateType !== TemplateType.RecurringPayroll &&
            templateType !== TemplateType.Payroll &&
            templateType !== TemplateType.Order &&
            templateType !== TemplateType.Invoice
        ) {
            this.duplicationHandlingOptions = [
                {
                    label: `Hopp over dupliserte ${entityLabelPlural || ''}`,
                    value: ImportOption.Skip,
                },
                {
                    label: `Overskriv eksisterende ${entityLabelPlural || ''}`,
                    value: ImportOption.Override,
                },
            ];
        }

        if (templateType === TemplateType.Product) {
            this.overwriteStatement = ImportStatement.ProductOverwriteStatement;
        }
    }

    ngOnDestroy() {
        this.loading$.complete();
    }

    onFilesChange(files: File[]) {
        this.file = files && files[0];

        if (this.file) {
            const type = this.file?.name?.toLowerCase().split(/[.]+/).pop();
            this.isValidFileFormat = type === 'txt' || type === 'xlsx' || type === 'csv';
        }
    }

    startImport() {
        if (!this.file) {
            this.isValidFileFormat = false;
            return;
        }

        if (this.importDefinition) {
            this.runCustomImport(this.importDefinition, this.file, this.duplicationHandling);
            return;
        }

        this.loading$.next(true);

        const templateType = this.options.data.templateType;
        let otherParams;

        if (templateType === TemplateType.Payroll) {
            otherParams = { payrollId: this.selectedPayrollRun.value };
        } else if (templateType === TemplateType.Order) {
            otherParams = { isDraft: this.selectedOrderOption.value === OrderOptions.Draft };
        } else if (templateType === TemplateType.Invoice) {
            otherParams = { vatFromUe: this.selectedInvoiceOption.value === InvoiceOptions.Ue };
        } else if (templateType === TemplateType.MainLedger) {
            this.accountService.invalidateCache();
        }

        this.importJobService
            .startImport(this.file, {
                jobName: this.options.data.jobName,
                entityType: this.options.data.entityType,
                templateType: this.options.data.templateType,
                description: this.options.data.description,
                importModel: {
                    DateFormat: this.selectedDateFormat?.type,
                    ImportOption: this.duplicationHandling || ImportOption.Skip,
                    OtherParams: otherParams,
                },
            })
            .subscribe({
                next: () => this.onClose.emit(),
                error: (err) => {
                    this.errorService.handle(err);
                    this.loading$.next(false);
                },
            });
    }

    async runCustomImport(def: ImportDefinition, file: File, duplicationHandling: ImportOption) {
        this.isImporting = true;
        this.submitButtonDisabled = true;
        this.cancelButtonLabel = 'Avbryt';
        await this.customImportComponent.importFile(file, def, {
            skipExisting: duplicationHandling == ImportOption.Skip,
        });
        this.submitButtonDisabled = false;
        this.submitLabel = 'Lukk';
        this.cancelButtonLabel = 'Tilbake';
    }

    onCustomImportCompleted($event) {
        if ($event) {
            this.onClose.emit();
            return;
        }
        this.isImporting = false;
    }

    openDisclaimerNote() {
        this.modalService.open(DisclaimerModal);
    }

    private loadPayrollRuns() {
        this.payrollRunsLoading = true;
        this.payrollService
            .getAll(`filter=StatusCode eq 0 or StatusCode eq null&orderby=ID desc`, true)
            .subscribe({
                next: (res) => {
                    this.payrollRuns = res.map((item) => ({ name: item.Description, value: item.ID })) || [];
                    this.selectedPayrollRun = this.payrollRuns[0];
                },
                error: (err) => this.errorService.handle(err),
            })
            .add(() => {
                this.payrollRunsLoading = false;
                this.submitButtonDisabled = !this.payrollRuns?.length;
            });
    }

    private getEntityLabelPlural(templateType: TemplateType) {
        switch (templateType) {
            case TemplateType.Customer:
                return 'kunder';
            case TemplateType.MainLedger:
                return 'kontoer';
            case TemplateType.Order:
                return 'ordre';
            case TemplateType.Invoice:
                return 'fakturaer';
            case TemplateType.Payroll:
                return 'lønnsposter';
            case TemplateType.Product:
                return 'produkter';
            case TemplateType.Supplier:
                return 'leverandører';
        }
    }

    onSubmit() {
        if (this.submitLabel == 'Lukk') {
            this.onClose.emit();
            return;
        }
        this.startImport();
    }

    async onCancel() {
        if (this.isImporting) {
            if (this.customImportComponent) {
                this.customImportComponent.Cancel();
            }
            this.cancelButtonLabel = 'Avbryt';
            this.submitLabel = 'Start import';
            this.submitButtonDisabled = false;
            return;
        }
        this.onClose.emit();
    }
}
