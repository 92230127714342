<section role="dialog" class="uni-modal" [class]="{ size1: !isImporting, size2: isImporting }">
    <header>{{ header }}</header>

    <article>
        @if (loading$ | async) {
            <section class="modal-spinner">
                <mat-spinner></mat-spinner>
            </section>
        }

        <div class="content" [class.hidden]="!isImporting">
            <custom-import-component
                #importComponent
                [definition]="importDefinition"
                (completed)="onCustomImportCompleted($event)"
            >
            </custom-import-component>
        </div>

        <div class="content" [class.hidden]="isImporting">
            <p>
                <strong>Klient: </strong>
                {{ authService?.activeCompany?.Name }}
            </p>
            @if (options.data.hasTemplateAccess && options.data.showTemplateDownloadAndDisclaimerInfo) {
                <p>
                    <strong>Last ned mal: </strong>
                    <a href="{{ options.data.downloadTemplateUrl }}">
                        {{ options.data.downloadTemplateUrl.split('/').pop() }}
                    </a>
                </p>
            }

            <p>{{ options.data.conditionalStatement }}</p>
            <p>{{ options.data.formatStatement }}</p>
            <p>{{ options.data.ekstraInfoStatement }}</p>

            @if (options.data.showTemplateDownloadAndDisclaimerInfo) {
                <p>
                    Vær vennlig og les gjennom
                    <a (click)="openDisclaimerNote()"> våre vilkår </a>
                    før du laster opp filen.
                </p>
            }

            <!-- File upload -->
            <label class="uni-label upload-label">
                <span>Filopplasting</span>

                <file-input
                    accept=".xlsx, .txt, .csv"
                    [upload]="false"
                    [multiple]="false"
                    (filesChange)="onFilesChange($event)"
                >
                </file-input>
            </label>

            @if (!isValidFileFormat) {
                <section class="alert error">
                    Vennligst legg til en gyldig fil (.xlsx eller .txt) for å importere
                </section>
            }

            <!-- Order -->
            @if (showOrderOptions) {
                <label class="uni-label label-left">
                    <span>Importer med status</span>
                    <uni-select [config]="selectConfig" [items]="orderOptions" [(value)]="selectedOrderOption">
                    </uni-select>
                </label>
            }

            <!-- Invoice-->
            @if (showInvoiceOptions) {
                <label class="uni-label label-left">
                    <span>Mva-koder basert på</span>
                    <uni-select [config]="selectConfig" [items]="invoiceOptions" [(value)]="selectedInvoiceOption">
                    </uni-select>
                </label>
            }

            <!-- Date format -->
            @if (showDateFormatSelect) {
                <label class="uni-label label-left">
                    <span>Datoformat</span>
                    <uni-select [config]="selectConfig" [items]="dateFormats" [(value)]="selectedDateFormat">
                    </uni-select>
                </label>
            }

            <!-- Payroll -->
            @if (showPayrollRunSelect && !payrollRunsLoading) {
                @if (payrollRuns?.length) {
                    <label class="uni-label label-left">
                        <span>Lønnsavregning</span>
                        <uni-select [config]="selectConfig" [items]="payrollRuns" [(value)]="selectedPayrollRun">
                        </uni-select>
                    </label>
                }

                @if (!payrollRuns?.length) {
                    <div class="missing-payroll">
                        Det må opprettes en
                        <a [routerLink]="['/salary/payrollrun/']" (click)="onClose.emit()"> lønnsavregning </a>
                        før du kan importere variable lønnsposter.
                    </div>
                }
            }

            <!-- Duplication handling -->
            @if (duplicationHandlingOptions?.length) {
                <mat-radio-group [(ngModel)]="duplicationHandling">
                    @for (option of duplicationHandlingOptions; track $index) {
                        <mat-radio-button [value]="option.value">
                            {{ option.label }}
                        </mat-radio-button>
                    }
                </mat-radio-group>
            }

            @if (overwriteStatement && duplicationHandling === importOptionOverride) {
                <section class="alert info">
                    <span> {{ overwriteStatement }} </span>
                </section>
            }
        </div>
    </article>

    <footer>
        <button class="secondary" (click)="onCancel()">{{ cancelButtonLabel }}</button>
        <button class="c2a" (click)="onSubmit()" [disabled]="submitButtonDisabled">
            {{ submitLabel }}
        </button>
    </footer>
</section>
