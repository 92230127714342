import {
    Component,
    Input,
    ChangeDetectionStrategy,
    Output,
    EventEmitter,
    OnDestroy,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import {
    IUniEditorButton,
    IUniEditorLayout,
} from '@app/components/common/uni-entity-editor/uni-editor-layout.interface';
import { IUniEditorChangeEvent } from '@app/components/common/uni-entity-editor/uni-editor-change-event.interface';
export interface IUniEntityEditorConfig {
    autoFocus?: boolean;
}
@Component({
    selector: 'uni-entity-editor',
    templateUrl: './uni-entity-editor.component.html',
    styleUrls: ['./uni-entity-editor.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniEntityEditorComponent implements OnChanges {
    @Input() editorLayouts: IUniEditorLayout[];
    @Input() model: any;
    @Input() config: IUniEntityEditorConfig;
    @Input() buttons: IUniEditorButton[];
    @Output() changeEvent: EventEmitter<IUniEditorChangeEvent> = new EventEmitter<IUniEditorChangeEvent>();

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes['editorLayouts']) {
            return;
        }
    }

    onEditorChange(event: IUniEditorChangeEvent) {
        if (this.hasChanges(event.changes)) {
            this.changeEvent.emit(event);
        }
    }

    private hasChanges(changes: SimpleChanges) {
        return Object.keys(changes).some((key) => changes[key].previousValue !== changes[key].currentValue);
    }
}
