<section class="row">
    <section class="col-md-5">
        <section class="form-info-section">
            <h1 *ngIf="translatedLayout.title">{{ translatedLayout.title }}</h1>
            <section [class]="description.class" *ngFor="let description of translatedLayout.descriptions">
                <h2 *ngIf="description.title && !description.hidden">{{ description.title }}</h2>
                <p *ngIf="!description.hidden">{{ description.text }}</p>
                <a
                    [href]="description.externalLink"
                    *ngIf="description.externalLink && !description.hidden"
                    target="_blank"
                    >{{ description.externalLinkAlias || description.externalLink }}</a
                >
            </section>
        </section>
    </section>

    <section class="col-md-7">
        @for (fieldGroup of translatedLayout.fieldGroups; track $index) {
            <section class="uni-card">
                @if (translatedLayout.title && $index === 0) {
                    <section class="card-header flex align-center justify-between">
                        <h2>{{ translatedLayout.title }}</h2>
                    </section>
                }
                @if (fieldGroup.fields?.length) {
                    <section class="uni-card-content">
                        <uni-form
                            [config]="{ showLabelAbove: true }"
                            [fields]="fieldGroup.fields"
                            [model]="model"
                            (changeEvent)="onChange($event)"
                        />
                    </section>
                }
            </section>
        }

        <section class="buttons">
            <button
                [attr.aria-busy]="button.isBusy$ && (button.isBusy$ | async)"
                [disabled]="button.isDisabled$ && (button.isDisabled$ | async)"
                [class]="button.class"
                (click)="onButtonClick(i)"
                *ngFor="let button of buttons; index as i"
            >
                {{ button.label }}
            </button>
        </section>
    </section>
</section>
